/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type InternalFileUploadCreateType =
  (typeof InternalFileUploadCreateType)[keyof typeof InternalFileUploadCreateType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InternalFileUploadCreateType = {
  generic: 'generic',
  cost_code: 'cost_code',
  estimate: 'estimate',
} as const
