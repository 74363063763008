/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListExternalCommunicationConfigsOrdering =
  (typeof ListExternalCommunicationConfigsOrdering)[keyof typeof ListExternalCommunicationConfigsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListExternalCommunicationConfigsOrdering = {
  license_number: 'license_number',
  '-license_number': '-license_number',
  phone: 'phone',
  '-phone': '-phone',
  default_invoice_memo: 'default_invoice_memo',
  '-default_invoice_memo': '-default_invoice_memo',
  default_net_terms: 'default_net_terms',
  '-default_net_terms': '-default_net_terms',
  display_business_email: 'display_business_email',
  '-display_business_email': '-display_business_email',
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
} as const
