/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListInternalFileUploadsOrdering =
  (typeof ListInternalFileUploadsOrdering)[keyof typeof ListInternalFileUploadsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListInternalFileUploadsOrdering = {
  type: 'type',
  '-type': '-type',
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
} as const
